import { MutableRefObject } from 'react';
import { scrollIntoView } from '@printdeal/ui-library';
import type { WindowLocation } from '@gatsbyjs/reach-router';
import type { SegmentationData } from '@printdeal/segmentation/react';
import {
  getFromLocalStorage, LS_KEYS, windowPresent, parseStringifiedValue, setInLocalStorage,
} from '../../helpers/window';
import { HomeProps } from '../../components/homepage/types';

export interface AudiencesData {
  type: string | null | undefined;
}

export interface AudienceOverRide {
  isNull: boolean;
  queryKey: string | null;
}

export const UNSUPPORTED_AUDIENCE_TYPES = [undefined, null, ''];

export const scrollToRef = (target: MutableRefObject<HTMLDivElement | null>) => {
  if (!target?.current) return;

  scrollIntoView(target.current, {
    block: 'start',
    behavior: 'smooth',
  });
};

/**
 *
 * @param audienceType audience type per loggedin user's industry
 * @param allHomepages all homepage records in contentful of content-type homepage
 * @returns only matching homepage record for audience
 */

export const getHomepageDataForAudience = (
  audienceType: string | null | undefined,
  allHomepages: HomeProps[],
  /**
   * Since we get from Contentful a string with comma separated audiences,
   * we have to split it and get the first value.
   * We are planning to operate multiple audiences in the future.
   * TODO: change the condition for undefiend to no_audience once the Contentful Audience APP is updated.
   */
) => allHomepages.find((homepage) => homepage.audienceType?.split(',')[0] === audienceType)
  || allHomepages.find((homepage) => homepage.audienceType?.split(',')[0] === undefined);

/**
 *
 * @param accountId customer id from customer slice/ redux store
 * @returns returns userId either from LS or Customer Slice, which one is available
 */
export const isRecognizedUser = (accountId?: string): boolean => {
  let ajsUserId: string | null = parseStringifiedValue(getFromLocalStorage(LS_KEYS.AJS_USER_ID));
  ajsUserId = ajsUserId === 'null' ? null : ajsUserId;
  const userId = ajsUserId || accountId;
  return !!userId;
};

/**
 *
 * @param location location from gatsby reach router
 * @param queryKey the exact query parameter, we are looking for
 * @returns an object with queryKey and it's value and whether the query key is valid
 */
export const getQueryParamFromLocation = (location: WindowLocation, queryKey: string) => {
  if (windowPresent) {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(queryKey);
  }
  return null;
};

/**
 * Moved this method from useMarketingBanner() hook to helper,
 * which decouples audience data from banner hook
 * @param customerTraits traits data which contains audiences
 * @param audienceLoadedFromSegment isSuccess from useSegmentation() hook
 * @param audienceOverRide over-ride params from url for playground
 * @returns current audience type
 */

export const getAudiences = (
  customerTraits: SegmentationData | undefined,
  audienceLoadedFromSegment: boolean,
  audienceOverRideForPlayground: string | null | undefined,
) => {
  const isAudienceOverRidden = Boolean(audienceOverRideForPlayground);
  /**
   * if audience override is present in the url location, ignore all other cases
   * & set the audience type from url location query parameters
   */
  if (isAudienceOverRidden) {
    return {
      type: audienceOverRideForPlayground,
    };
  }

  if (!isAudienceOverRidden && audienceLoadedFromSegment) {
    const filteredAudiences = customerTraits?.audiences
      .find((audience) => !UNSUPPORTED_AUDIENCE_TYPES.includes(audience)) || 'no_audience';
    /**
     * If the user is recognized, we need to store the audience type as well in LS
     */
    setInLocalStorage(LS_KEYS.AUDIENCE_TYPE, filteredAudiences);
    return {
      type: filteredAudiences,
    };
  }
  // Traits with no audience, display default content.
  return {
    type: 'no_audience',
  };
};
